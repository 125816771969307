export const founderMockStartups = [
  {
    id: 1,
    name: "Startup name Prism",
    shortDescription: "Short description Prism is the best startup ever",
    startupLocationDto: {
      id: 1,
      name: "Bangladesh",
    },
    industryDto: {
      id: 1,
      name: "Technology & Software",
    },
    businessModelDto: {
      id: 1,
      name: "Business-to-business (B2B) model",
    },
    problem: "Problem is Prism",
    solution: "Solution to use Prism",
    ageRangeDto: {
      id: 1,
      name: "0-100",
    },
    incomeLevelDto: {
      id: 1,
      name: "Low income",
    },
    buyingMotivationDto: {
      id: 1,
      name: "Quality",
    },
    lifestylePreferenceDto: {
      id: 1,
      name: "Urban",
    },
    educationLevelDto: {
      id: 1,
      name: "High school or equivalent",
    },
    targetGenderDto: {
      id: 1,
      name: "Masculine gender",
    },
    targetGeographyDto: {
      id: 1,
      name: "North America",
    },
    targetAudienceInterest:
      "Target audience interest all the people in the world",
    keyDifferentiator: "Key diff best Prizm",
    startupLogoUrl:
      "https://avatars.mds.yandex.net/i?id=f7b44e636097d36266ac06b1d0ca7fde9cf50a78-8168927-images-thumbs&ref=rim&n=33&w=400&h=400",
    announcementDay: "2024-11-17",
    originFounderDto: {
      id: 1,
      userDto: {
        id: 3,
        telegramId: 33333,
        email: "user_3@mail.ru",
        firstName: "Dilon",
        lastName: "Tusk",
        username: "username 3",
        avatarUrl:
          "https://i.pinimg.com/originals/0d/f6/ef/0df6ef3f82d71ac3658468a7a370c0e9.png",
      },
      linkedInProfile: "linked_in_profile DilonT",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      skillDtos: [
        {
          id: 1,
          name: "Python",
          skillCategoryDtos: [
            {
              id: 1,
              name: "Programming Languages",
              skillType: "HARD",
              coBuilderPositionDtos: [
                {
                  id: 1,
                  name: "Developer",
                },
              ],
            },
          ],
          coBuilderPositionDtoList: [
            {
              id: 1,
              name: "Developer",
            },
          ],
        },
        {
          id: 4,
          name: "HTML/CSS",
          skillCategoryDtos: [
            {
              id: 2,
              name: "Web Development",
              skillType: "HARD",
              coBuilderPositionDtos: [
                {
                  id: 1,
                  name: "Developer",
                },
              ],
            },
          ],
          coBuilderPositionDtoList: [
            {
              id: 1,
              name: "Developer",
            },
          ],
        },
      ],
      primaryLanguageDtos: [
        {
          id: 1,
          name: "English",
        },
        {
          id: 2,
          name: "German",
        },
      ],
    },
    tokenDto: {
      id: 1,
      name: "Token name 1",
      tokenTicker: "TT 1",
      totalSupply: 100000,
      tokenLogoUrl: "Logo url",
    },
    balance: 100000,
    startupStatus: "CREATED",
    competitors: ["competitor_url 1", "competitor_url 2"],
    directorDtoList: [
      {
        id: 1,
        userDto: {
          id: 3,
          telegramId: 33333,
          email: "user_3@mail.ru",
          firstName: "first_name 3",
          lastName: "last_name 3",
          username: "username 3",
          avatarUrl: "avatar_url 3",
        },
        linkedInProfile: "linked_in_profile 1",
        founderPositionDto: {
          id: 1,
          name: "SOFTWARE DEVELOPMENT",
          coBuilderPositionDtoList: [
            {
              id: 1,
              name: "Developer",
            },
            {
              id: 3,
              name: "Tester",
            },
          ],
        },
        skillDtos: [
          {
            id: 1,
            name: "Python",
            skillCategoryDtos: [
              {
                id: 1,
                name: "Programming Languages",
                skillType: "HARD",
                coBuilderPositionDtos: [
                  {
                    id: 1,
                    name: "Developer",
                  },
                ],
              },
            ],
            coBuilderPositionDtoList: [
              {
                id: 1,
                name: "Developer",
              },
            ],
          },
          {
            id: 4,
            name: "HTML/CSS",
            skillCategoryDtos: [
              {
                id: 2,
                name: "Web Development",
                skillType: "HARD",
                coBuilderPositionDtos: [
                  {
                    id: 1,
                    name: "Developer",
                  },
                ],
              },
            ],
            coBuilderPositionDtoList: [
              {
                id: 1,
                name: "Developer",
              },
            ],
          },
        ],
        primaryLanguageDtos: [
          {
            id: 1,
            name: "English",
          },
          {
            id: 2,
            name: "German",
          },
        ],
      },
      {
        id: 2,
        userDto: {
          id: 4,
          telegramId: 44444,
          email: "user_4@mail.ru",
          firstName: "first_name 4",
          lastName: "last_name 4",
          username: "username 4",
          avatarUrl: "avatar_url 4",
        },
        linkedInProfile: "linked_in_profile 2",
        founderPositionDto: {
          id: 2,
          name: "SALES & MARKETING",
          coBuilderPositionDtoList: [
            {
              id: 2,
              name: "Designer",
            },
            {
              id: 6,
              name: "Marketers",
            },
            {
              id: 7,
              name: "Sales Managers",
            },
          ],
        },
        skillDtos: [
          {
            id: 19,
            name: "Adobe Photoshop",
            skillCategoryDtos: [
              {
                id: 7,
                name: "Design Software",
                skillType: "HARD",
                coBuilderPositionDtos: [
                  {
                    id: 2,
                    name: "Designer",
                  },
                ],
              },
            ],
            coBuilderPositionDtoList: [
              {
                id: 2,
                name: "Designer",
              },
            ],
          },
          {
            id: 22,
            name: "Wireframing",
            skillCategoryDtos: [
              {
                id: 8,
                name: "UI/UX Design",
                skillType: "HARD",
                coBuilderPositionDtos: [
                  {
                    id: 2,
                    name: "Designer",
                  },
                ],
              },
            ],
            coBuilderPositionDtoList: [
              {
                id: 2,
                name: "Designer",
              },
            ],
          },
        ],
        primaryLanguageDtos: [
          {
            id: 3,
            name: "French",
          },
          {
            id: 4,
            name: "Italian",
          },
        ],
      },
    ],
  },
];

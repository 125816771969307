import React from "react";
import { Button } from "@mui/material";

type CustomButtonProps = {
  icon?: React.ReactNode;
  altText?: string;
  disabled?: boolean;
  variant?: "light" | "dark";
  onClick?: () => void;
};

const SquareButton: React.FC<CustomButtonProps> = ({
  icon,
  altText = "Arrow Button",
  disabled = false,
  variant = "light",
  onClick,
}) => {
  const backgroundColor =
    variant === "light" ? "var(--violet-3)" : "var(--violet-gr-2)";
  const hoverBackgroundColor =
    variant === "light" ? "var(--violet-opacity)" : "var(--violet-gr-3)";
  const iconColor =
    variant === "light" ? "var(--violet-gr-2)" : "var(--text-light)";

  return (
    <Button
      sx={{
        width: "30px",
        height: "24px",
        backgroundColor: backgroundColor,
        borderRadius: "4px",
        minWidth: "30px",
        minHeight: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.15)",
        "&:hover": {
          backgroundColor: hoverBackgroundColor,
        },
      }}
      disabled={disabled}
      aria-label={altText}
      onClick={onClick}
    >
      {icon
        ? React.cloneElement(icon as React.ReactElement, {
            style: { color: iconColor },
          })
        : null}
    </Button>
  );
};

export default SquareButton;

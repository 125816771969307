import { Home, Settings } from "@mui/icons-material";
import {
  CustomButton,
  CustomSquare,
  RoundButton,
  SquareButton,
} from "../../ui-kit";
import styles from "./UiKit.module.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import KeyboardDoubleArrowRightTwoToneIcon from "@mui/icons-material/KeyboardDoubleArrowRightTwoTone";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CustomSmallButton from "../../ui-kit/CustomSmallButton/CustomSmallButton";

function UiKit(): React.JSX.Element {
  const handleClick = () => {
    console.log("Button clicked!");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <h1>UI Kit</h1>

      <h2>Buttons</h2>

      <h3>CustomButton from './CustomButton/CustomButton';</h3>
      <div className={styles.margin}>Button with text and icon</div>
      <CustomButton
        text="Home"
        icon={<Home />}
        color="var(--color-primary)"
        size="wide"
      />

      <div className={styles.margin}>
        Button with text, icon and color change
      </div>
      <CustomButton
        text="Settings"
        icon={<Settings />}
        color="var(--violet-gr-2)"
        size="narrow"
      />

      <div className={styles.margin}>Button with text only</div>
      <CustomButton text="Submit" color="var(--violet-gr-3)" size="wide" />

      <div className={styles.margin}>Button that is disabled</div>
      <CustomButton
        text="Disabled"
        disabled={true}
        color="var(--violet-gr-1)"
        size="narrow"
      />

      <h2>Fields</h2>

      <h3>import SearchField from './CustomSearchField/CustomSearchField';</h3>

      <div className={styles.margin}>SearchField</div>
      {/* <SearchField /> */}

      <h3>import CustomTextField from './CustomTextField/CustomTextField';</h3>

      <h2>Round buttons with icons</h2>

      <h2>Icons</h2>

      <h3>import RoundButton from './RoundButton/RoundButton';</h3>

      <div className={styles.margin}>icon with the symbol Add</div>

      <RoundButton
        icon={<AddIcon />}
        altText="Add icon"
        disabled={false}
        onClick={handleClick}
      />
      <div className={styles.margin}>dark Add</div>
      <RoundButton
        icon={<AddIcon />}
        altText="Add icon"
        disabled={false}
        variant="dark"
        onClick={handleClick}
      />

      <div className={styles.margin}>icon with the symbol Remove</div>

      <RoundButton
        icon={<RemoveIcon />}
        altText="Remove icon"
        disabled={false}
        onClick={handleClick}
      />
      <div className={styles.margin}>dark Remove</div>

      <RoundButton
        icon={<RemoveIcon />}
        altText="Remove icon"
        disabled={false}
        variant="dark"
        onClick={handleClick}
      />

      <div className={styles.margin}>icon with color the symbol Close</div>

      <RoundButton
        icon={<CloseIcon />}
        altText="Close icon"
        disabled={false}
        onClick={handleClick}
      />

      <div className={styles.margin}>dark Close</div>

      <RoundButton
        icon={<CloseIcon />}
        altText="Close icon"
        disabled={false}
        variant="dark"
        onClick={handleClick}
      />

      <div className={styles.margin}>icon with color the without symbol</div>

      <RoundButton disabled={false} onClick={handleClick} />

      <div className={styles.margin}>
        icon with color with image the without symbol
      </div>

      <RoundButton
        disabled={false}
        onClick={handleClick}
        img={
          "https://media.gq.com/photos/5a9755bf5329a30b1ccdffea/master/w_2000,h_1333,c_limit/jcrew-ludlow-black-suit.jpg"
        }
      />

      <div className={styles.margin}>
        icon with dark color the without symbol
      </div>

      <RoundButton disabled={false} variant="dark" onClick={handleClick} />

      <div className={styles.margin}>
        icon with color the symbol TuneTwoToneIcon
      </div>

      <RoundButton
        icon={<TuneTwoToneIcon />}
        altText="TuneTwoToneIcon icon"
        disabled={false}
        onClick={handleClick}
      />

      <div className={styles.margin}>
        dark icon with color the symbol TuneTwoToneIcon
      </div>

      <RoundButton
        icon={<TuneTwoToneIcon />}
        altText="TuneTwoToneIcon icon"
        disabled={false}
        variant="dark"
        onClick={handleClick}
      />

      <h3>import SquareButton from './SquareButton/SquareButton';</h3>

      <div className={styles.margin}>
        dark icon with color the symbol ArrowForwardIcon
      </div>

      <SquareButton
        icon={<ChevronRightTwoToneIcon />}
        altText="ArrowForwardIcon icon"
        disabled={false}
        variant="dark"
        onClick={handleClick}
      />

      <h3>Simple Icons</h3>

      <KeyboardDoubleArrowRightTwoToneIcon
        style={{ color: "var(--violet-gr-2)" }}
      />
      <KeyboardDoubleArrowRightTwoToneIcon
        style={{ color: "var(--violet-3)" }}
      />
      <AddIcon style={{ color: "var(--violet-gr-2)" }} />
      <AddIcon style={{ color: "var(--violet-3)" }} />
      <ChevronRightTwoToneIcon style={{ color: "var(--violet-gr-2)" }} />
      <DoneTwoToneIcon style={{ color: "var(--violet-gr-2)" }} />
      <LockOutlinedIcon style={{ color: "var(--violet-gr-2)" }} />
      <NotificationsNoneOutlinedIcon style={{ color: "var(--violet-gr-2)" }} />

      <h2>Blocks</h2>

      <h1>Используемые шрифты:</h1>
      <h1>- Пример текста H1</h1>
      <h2>- Пример текста H2</h2>
      <h3>- Пример текста H3</h3>
      <p className="p1">- Пример текста P1</p>
      <p className="p2">- Пример текста P2</p>
      <p className="p3">- Пример текста P3</p>

      <div className={styles.margin}>CustomSquare without logo</div>
      <CustomSquare />

      <div className={styles.margin}>CustomSquare with image</div>
      <CustomSquare
        imageSrc={
          "https://avatars.mds.yandex.net/i?id=f7b44e636097d36266ac06b1d0ca7fde9cf50a78-8168927-images-thumbs&ref=rim&n=33&w=400&h=400"
        }
      />

      <CustomSmallButton
        text="Assigned"
        color="var(--violet-7)"
        height="24px"
      />
      <CustomSmallButton text="In Review" color="var(--yellow)" height="24px" />
      <CustomSmallButton text="Revision" color="var(--coral)" height="24px" />
      <CustomSmallButton text="Revision" height="24px" textColor="dark" />
    </div>
  );
}

export default UiKit;

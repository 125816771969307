import { useNavigate } from "react-router-dom";
import styles from "./filters.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton, RoundButton } from "../../ui-kit";
import CustomSmallButton from "../../ui-kit/CustomSmallButton/CustomSmallButton";

function Filters(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  const handleCloseClick = () => {
    navigate(-1);
  };

  return (
    <div className={`${styles.main} container`}>
      <div className={styles.filter_container}>
        <div className={styles.title}>
          <RoundButton
            icon={<CloseIcon />}
            altText="Close icon"
            disabled={false}
            variant="dark"
            onClick={handleCloseClick}
          />
          <h1>Filters</h1>
          <CustomSmallButton text="Revision" height="24px" textColor="dark" />
        </div>
        <CustomButton text="Confirm" onClick={handleOnClick} />
      </div>
    </div>
  );
}

export default Filters;

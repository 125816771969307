import { useNavigate } from "react-router-dom";
import styles from "./Main.module.css";
import { CustomButton } from "../../../ui-kit";
import { SPRINTS_PATH } from "../../../utils/constants";
import { mockData } from "../../../utils/01.mockData";
import { FounderDto } from "../../../utils/types";
import StartupCard from "../../../components/pagesComponents/founder/Main/StartupCard";
import { founderMockStartups } from "../../../utils/02_founderMockStartups";

function FounderMain({ userTelegramData }: { userTelegramData: string }): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(SPRINTS_PATH);
  };

  const founderData: FounderDto | undefined = mockData?.founderMockData?.founderDto;
  const startup = founderMockStartups[0];

  return (
    <div className={`${styles.main} container`}>
      <h2>Hey there!</h2>
      {founderData ? (
        <>
          <h2>{`${founderData.userDto.firstName} ${founderData.userDto.lastName}`}</h2>
          <h2>
            {`${userTelegramData ? userTelegramData : `@${founderData.userDto.username}`}`}
          </h2>
          <div className={styles.text}>
            It seems that you are one of the Founders of the "Startup" name
          </div>

          <StartupCard
            name={startup.name}
            industry={startup.industryDto.name}
            description={startup.shortDescription}
            logo={startup.startupLogoUrl}
          />
        </>
      ) : (
        <p>Loading founder data...</p>
      )}
      <CustomButton text="Next" onClick={handleOnClick} />
    </div>
  );
}

export default FounderMain;

import { useNavigate } from "react-router-dom";
import styles from "./startupTrack.module.css";
import { CustomButton } from "../../ui-kit";

function StartupTrack(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <div className={`${styles.main} container`}>
      <h1>Startup track</h1>
      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default StartupTrack;

import { founderMockData } from "./01.1_founderMockData";
import { coBuilderMockData } from "./01.2_coBuilderMockData";
import { noDataMatchMock } from "./01.3_noDataMatchMock";

import { MockData } from "./types";

export const mockData: MockData = {
  founderMockData,
  coBuilderMockData,
  noDataMatchMock,
};

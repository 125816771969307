export const coBuilderMockData = {
  founderDto: null,
  coBuilderDto: {
    id: 1,
    userDto: {
      id: 1,
      telegramId: 11111,
      email: "user_1@mail.ru",
      firstName: "John",
      lastName: "Doe",
      username: "johndoe",
      avatarUrl: "https://example.com/avatar1.jpg",
    },
    linkedInProfile: "https://linkedin.com/in/johndoe",
    coBuilderPositionDto: {
      id: 1,
      name: "Developer",
    },
    skillDtos: [
      {
        id: 1,
        name: "Python",
        skillCategoryDtos: [
          {
            id: 1,
            name: "Programming Languages",
            skillType: "HARD",
            coBuilderPositionDtos: [
              {
                id: 1,
                name: "Developer",
              },
            ],
          },
        ],
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
        ],
      },
      {
        id: 4,
        name: "HTML/CSS",
        skillCategoryDtos: [
          {
            id: 2,
            name: "Web Development",
            skillType: "HARD",
            coBuilderPositionDtos: [
              {
                id: 1,
                name: "Developer",
              },
            ],
          },
        ],
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
        ],
      },
    ],
    primaryLanguageDtos: [
      {
        id: 1,
        name: "English",
      },
      {
        id: 2,
        name: "German",
      },
    ],
  },
};

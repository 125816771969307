import React from "react";
import { Button } from "@mui/material";

type CustomButtonProps = {
  color?: string;
  textColor?: "light" | "dark";
  size?: "wide" | "narrow";
  text: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

const CustomButton: React.FC<CustomButtonProps> = ({
  color = "linear-gradient(0.25turn, var(--violet-gr-1), var(--violet-gr-2), var(--violet-gr-3))",
  textColor = "light",
  size = "wide",
  text,
  icon,
  disabled = false,
  onClick,
}) => {
  return (
    <Button
      sx={{
        width: size === "wide" ? "311px" : "140px",
        height: "60px",
        background: color,
        borderRadius: "12px",
        fontFamily: "'SF Pro Display', 'Roboto', sans-serif",
        fontSize: "var(--font-size-l)",
        fontWeight: "var(--font-weight-semibold)",
        boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.15)",
        color:
          textColor === "light" ? "var(--text-light)" : "var(--violet-gr-2)",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "var(--color-secondary)",
        },
      }}
      disabled={disabled}
      endIcon={icon}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CustomButton;

import { useNavigate } from "react-router-dom";
import styles from "./newSprint.module.css";
import { CustomButton } from "../../ui-kit";

function NewSprint(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <div className={`${styles.main} container`}>
      <h1>Plan new sprint</h1>
      <h2>Sunday fun day!</h2>
      <p className="p2">
        Every Sunday will bring you more open tasks that need to be approved
      </p>
      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default NewSprint;

export const founderMockData = {
  founderDto: {
    id: 1,
    userDto: {
      id: 3,
      telegramId: 33333,
      email: "michael.smith@mail.ru",
      firstName: "Michael",
      lastName: "Smith",
      username: "michaelsmith",
      avatarUrl: "https://media.gq.com/photos/5a9755bf5329a30b1ccdffea/master/w_2000,h_1333,c_limit/jcrew-ludlow-black-suit.jpg",
    },
    linkedInProfile: "https://linkedin.com/in/michael-smith",
    founderPositionDto: {
      id: 1,
      name: "SOFTWARE DEVELOPMENT",
      coBuilderPositionDtoList: [
        {
          id: 1,
          name: "Developer",
        },
        {
          id: 3,
          name: "Tester",
        },
      ],
    },
    skillDtos: [
      {
        id: 1,
        name: "Python",
        skillCategoryDtos: [
          {
            id: 1,
            name: "Programming Languages",
            skillType: "HARD",
            coBuilderPositionDtos: [
              {
                id: 1,
                name: "Developer",
              },
            ],
          },
        ],
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
        ],
      },
      {
        id: 4,
        name: "HTML/CSS",
        skillCategoryDtos: [
          {
            id: 2,
            name: "Web Development",
            skillType: "HARD",
            coBuilderPositionDtos: [
              {
                id: 1,
                name: "Developer",
              },
            ],
          },
        ],
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
        ],
      },
    ],
    primaryLanguageDtos: [
      {
        id: 1,
        name: "English",
      },
      {
        id: 2,
        name: "German",
      },
    ],
  },
  coBuilderDto: null,
};

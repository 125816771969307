import styles from "./job.module.css";
import { CustomSquare } from "../../ui-kit";

type TJobItem = {
  id: number;
  nameOne: string;
  nameTwo: string;
  nameThree: string;
  position: string;
  grade: string;
  bid: number;
  bonus: number;
  key?: string;
};

type JobProps = {
  item: TJobItem;
};

function Job({ item }: JobProps): React.JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles.logo_text_container}>
        <CustomSquare />
        <div className={styles.container}>
          <h2>{item.nameOne}</h2>
          <p className={`p2 ${styles.text_left}`}>{item.nameTwo}</p>
        </div>
      </div>
      <p className={`p2 ${styles.text_left}`}>{item.nameThree}</p>
      <div className={styles.text_container}>
        <h3>Position:</h3>
        <p className="p2">{item.position}</p>
      </div>
      <div className={styles.text_container}>
        <h3>Grade:</h3>
        <p className="p2">{item.grade}</p>
      </div>
      <div className={styles.text_container}>
        <h3>Bid:</h3>
        <p className="p2">{item.bid}</p>
      </div>
      <div className={styles.text_container}>
        <h3>Bonus Bid:</h3>
        <p className="p2">{item.bonus}</p>
      </div>
    </div>
  );
}

export default Job;

export const coBuilderMockStartups = [
  {
    id: 1,
    startupId: 1,
    startDate: "2024-11-17",
    dueDate: "2024-11-24",
    reference: "reference",
    instruction: "instruction",
    bid: 200,
    bonus: 100,
    taskShortDto: {
      id: 1,
      name: "Technology & Software. Task 1",
      goal: "goal",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      grade: "grade",
      output: "output",
      taskCategoryDto: {
        id: 1,
        name: "Technology & Software. Task category 1",
        sprintDto: {
          id: 1,
          fullName: "Technology & Software. Sprint 1",
          shortName: "Sprint 1",
          stageDto: {
            id: 1,
            fullName: "Technology & Software. Stage 1",
            shortName: "Stage 1",
            industryDto: {
              id: 1,
              name: "Technology & Software",
            },
          },
          prevSprintDto: null,
        },
      },
      defaultBid: 100,
      maxBid: 500,
      taskActionList: ["action 1", "action 2"],
      taskSuccessKPIList: ["kpi 1", "kpi 2"],
      skillIdList: [1, 2, 3],
    },
    status: "UNASSIGNED",
    deliverableList: ["deliverable 1", "deliverable 2", "deliverable 3"],
  },
  {
    id: 2,
    startupId: 1,
    startDate: "2024-11-17",
    dueDate: "2024-11-24",
    reference: "reference",
    instruction: "instruction",
    bid: 200,
    bonus: 100,
    taskShortDto: {
      id: 2,
      name: "Technology & Software. Task 2",
      goal: "goal",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      grade: "grade",
      output: "output",
      taskCategoryDto: {
        id: 1,
        name: "Technology & Software. Task category 1",
        sprintDto: {
          id: 1,
          fullName: "Technology & Software. Sprint 1",
          shortName: "Sprint 1",
          stageDto: {
            id: 1,
            fullName: "Technology & Software. Stage 1",
            shortName: "Stage 1",
            industryDto: {
              id: 1,
              name: "Technology & Software",
            },
          },
          prevSprintDto: null,
        },
      },
      defaultBid: 100,
      maxBid: 500,
      taskActionList: ["action 1", "action 2"],
      taskSuccessKPIList: ["kpi 1", "kpi 2"],
      skillIdList: [4, 5, 6],
    },
    status: "UNASSIGNED",
    deliverableList: ["deliverable 1", "deliverable 2", "deliverable 3"],
  },
  {
    id: 7,
    startupId: 1,
    startDate: "2024-11-24",
    dueDate: "2024-12-01",
    reference: "reference",
    instruction: "instruction",
    bid: 200,
    bonus: 100,
    taskShortDto: {
      id: 7,
      name: "Technology & Software. Task 7",
      goal: "goal",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      grade: "grade",
      output: "output",
      taskCategoryDto: {
        id: 2,
        name: "Technology & Software. Task category 2",
        sprintDto: {
          id: 2,
          fullName: "Technology & Software. Sprint 2",
          shortName: "Sprint 2",
          stageDto: {
            id: 1,
            fullName: "Technology & Software. Stage 1",
            shortName: "Stage 1",
            industryDto: {
              id: 1,
              name: "Technology & Software",
            },
          },
          prevSprintDto: {
            id: 1,
            fullName: "Technology & Software. Sprint 1",
            shortName: "Sprint 1",
            stageDto: {
              id: 1,
              fullName: "Technology & Software. Stage 1",
              shortName: "Stage 1",
              industryDto: {
                id: 1,
                name: "Technology & Software",
              },
            },
            prevSprintDto: null,
          },
        },
      },
      defaultBid: 100,
      maxBid: 500,
      taskActionList: ["action 1", "action 2"],
      taskSuccessKPIList: ["kpi 1", "kpi 2"],
      skillIdList: [1, 2, 3],
    },
    status: "UNASSIGNED",
    deliverableList: ["deliverable 1", "deliverable 2", "deliverable 3"],
  },
  {
    id: 8,
    startupId: 1,
    startDate: "2024-11-24",
    dueDate: "2024-12-01",
    reference: "reference",
    instruction: "instruction",
    bid: 200,
    bonus: 100,
    taskShortDto: {
      id: 8,
      name: "Technology & Software. Task 8",
      goal: "goal",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      grade: "grade",
      output: "output",
      taskCategoryDto: {
        id: 2,
        name: "Technology & Software. Task category 2",
        sprintDto: {
          id: 2,
          fullName: "Technology & Software. Sprint 2",
          shortName: "Sprint 2",
          stageDto: {
            id: 1,
            fullName: "Technology & Software. Stage 1",
            shortName: "Stage 1",
            industryDto: {
              id: 1,
              name: "Technology & Software",
            },
          },
          prevSprintDto: {
            id: 1,
            fullName: "Technology & Software. Sprint 1",
            shortName: "Sprint 1",
            stageDto: {
              id: 1,
              fullName: "Technology & Software. Stage 1",
              shortName: "Stage 1",
              industryDto: {
                id: 1,
                name: "Technology & Software",
              },
            },
            prevSprintDto: null,
          },
        },
      },
      defaultBid: 100,
      maxBid: 500,
      taskActionList: ["action 1", "action 2"],
      taskSuccessKPIList: ["kpi 1", "kpi 2"],
      skillIdList: [4, 5, 6],
    },
    status: "UNASSIGNED",
    deliverableList: ["deliverable 1", "deliverable 2", "deliverable 3"],
  },
  {
    id: 1,
    startupId: 1,
    startDate: "2024-11-17",
    dueDate: "2024-11-24",
    reference: "reference",
    instruction: "instruction",
    bid: 200,
    bonus: 100,
    taskShortDto: {
      id: 1,
      name: "Technology & Software. Task 1",
      goal: "goal",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      grade: "grade",
      output: "output",
      taskCategoryDto: {
        id: 1,
        name: "Technology & Software. Task category 1",
        sprintDto: {
          id: 1,
          fullName: "Technology & Software. Sprint 1",
          shortName: "Sprint 1",
          stageDto: {
            id: 1,
            fullName: "Technology & Software. Stage 1",
            shortName: "Stage 1",
            industryDto: {
              id: 1,
              name: "Technology & Software",
            },
          },
          prevSprintDto: null,
        },
      },
      defaultBid: 100,
      maxBid: 500,
      taskActionList: ["action 1", "action 2"],
      taskSuccessKPIList: ["kpi 1", "kpi 2"],
      skillIdList: [1, 2, 3],
    },
    status: "UNASSIGNED",
    deliverableList: ["deliverable 1", "deliverable 2", "deliverable 3"],
  },
  {
    id: 2,
    startupId: 1,
    startDate: "2024-11-17",
    dueDate: "2024-11-24",
    reference: "reference",
    instruction: "instruction",
    bid: 200,
    bonus: 100,
    taskShortDto: {
      id: 2,
      name: "Technology & Software. Task 2",
      goal: "goal",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      grade: "grade",
      output: "output",
      taskCategoryDto: {
        id: 1,
        name: "Technology & Software. Task category 1",
        sprintDto: {
          id: 1,
          fullName: "Technology & Software. Sprint 1",
          shortName: "Sprint 1",
          stageDto: {
            id: 1,
            fullName: "Technology & Software. Stage 1",
            shortName: "Stage 1",
            industryDto: {
              id: 1,
              name: "Technology & Software",
            },
          },
          prevSprintDto: null,
        },
      },
      defaultBid: 100,
      maxBid: 500,
      taskActionList: ["action 1", "action 2"],
      taskSuccessKPIList: ["kpi 1", "kpi 2"],
      skillIdList: [4, 5, 6],
    },
    status: "UNASSIGNED",
    deliverableList: ["deliverable 1", "deliverable 2", "deliverable 3"],
  },
  {
    id: 7,
    startupId: 1,
    startDate: "2024-11-24",
    dueDate: "2024-12-01",
    reference: "reference",
    instruction: "instruction",
    bid: 200,
    bonus: 100,
    taskShortDto: {
      id: 7,
      name: "Technology & Software. Task 7",
      goal: "goal",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      grade: "grade",
      output: "output",
      taskCategoryDto: {
        id: 2,
        name: "Technology & Software. Task category 2",
        sprintDto: {
          id: 2,
          fullName: "Technology & Software. Sprint 2",
          shortName: "Sprint 2",
          stageDto: {
            id: 1,
            fullName: "Technology & Software. Stage 1",
            shortName: "Stage 1",
            industryDto: {
              id: 1,
              name: "Technology & Software",
            },
          },
          prevSprintDto: {
            id: 1,
            fullName: "Technology & Software. Sprint 1",
            shortName: "Sprint 1",
            stageDto: {
              id: 1,
              fullName: "Technology & Software. Stage 1",
              shortName: "Stage 1",
              industryDto: {
                id: 1,
                name: "Technology & Software",
              },
            },
            prevSprintDto: null,
          },
        },
      },
      defaultBid: 100,
      maxBid: 500,
      taskActionList: ["action 1", "action 2"],
      taskSuccessKPIList: ["kpi 1", "kpi 2"],
      skillIdList: [1, 2, 3],
    },
    status: "UNASSIGNED",
    deliverableList: ["deliverable 1", "deliverable 2", "deliverable 3"],
  },
  {
    id: 8,
    startupId: 1,
    startDate: "2024-11-24",
    dueDate: "2024-12-01",
    reference: "reference",
    instruction: "instruction",
    bid: 200,
    bonus: 100,
    taskShortDto: {
      id: 8,
      name: "Technology & Software. Task 8",
      goal: "goal",
      founderPositionDto: {
        id: 1,
        name: "SOFTWARE DEVELOPMENT",
        coBuilderPositionDtoList: [
          {
            id: 1,
            name: "Developer",
          },
          {
            id: 3,
            name: "Tester",
          },
        ],
      },
      grade: "grade",
      output: "output",
      taskCategoryDto: {
        id: 2,
        name: "Technology & Software. Task category 2",
        sprintDto: {
          id: 2,
          fullName: "Technology & Software. Sprint 2",
          shortName: "Sprint 2",
          stageDto: {
            id: 1,
            fullName: "Technology & Software. Stage 1",
            shortName: "Stage 1",
            industryDto: {
              id: 1,
              name: "Technology & Software",
            },
          },
          prevSprintDto: {
            id: 1,
            fullName: "Technology & Software. Sprint 1",
            shortName: "Sprint 1",
            stageDto: {
              id: 1,
              fullName: "Technology & Software. Stage 1",
              shortName: "Stage 1",
              industryDto: {
                id: 1,
                name: "Technology & Software",
              },
            },
            prevSprintDto: null,
          },
        },
      },
      defaultBid: 100,
      maxBid: 500,
      taskActionList: ["action 1", "action 2"],
      taskSuccessKPIList: ["kpi 1", "kpi 2"],
      skillIdList: [4, 5, 6],
    },
    status: "REVISION",
    deliverableList: ["deliverable 1", "deliverable 2", "deliverable 3"],
  },
];

import { Link, useNavigate } from "react-router-dom";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import styles from "./Sprints.module.css";
import { CustomButton, RoundButton } from "../../../ui-kit";
import {
  NEWSPRINT,
  SPRINTRETRO,
  STARTUPTRACK,
  URGENTTASKS,
  PROFILE_PATH
} from "../../../utils/constants";
import { founderMockData } from "../../../utils/01.1_founderMockData";

function Sprints(): React.JSX.Element {
  const navigate = useNavigate();
  const handleOnClickToProfile = () => {
    navigate(PROFILE_PATH);
  };

  return (
    <div className={`${styles.main} container`}>
      <div className={styles.sprints__header}>
        <div className={styles.sprints__header__logo}>
          <RoundButton disabled={false} onClick={handleOnClickToProfile} img={founderMockData.founderDto.userDto.avatarUrl} />
        </div>
        <div className={styles.sprints__header__text}>
          <h1>My Sprints</h1>
        </div>
      </div>
      <div className={styles.sprints__buttons_container}>
        <Link to={STARTUPTRACK}>
          <CustomButton text="Startup track" />
        </Link>
        <Link to={SPRINTRETRO}>
          <CustomButton text="Sprint retro" />
        </Link>
        <Link to={NEWSPRINT}>
          <CustomButton
            text="Plan new sprint"
            icon={
              <NotificationsNoneOutlinedIcon
                style={{ color: "var(--white)" }}
              />
            }
          />
        </Link>
        <Link to={URGENTTASKS}>
          <CustomButton text="Urgent Tasks" />
        </Link>
      </div>
    </div>
  );
}

export default Sprints;

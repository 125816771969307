import { CustomSquare } from "../../../../ui-kit";
import styles from "./StartupCard.module.css";

interface StartupCardProps {
  name: string;
  industry: string;
  description: string;
  logo: string;
}

function StartupCard({ name, industry, description, logo }: StartupCardProps): React.JSX.Element {
  return (
    <div className={styles.startup}>
      <div>
        <div className={styles.startup__header}>
          <CustomSquare imageSrc={logo} />
          <div>
            <div className={styles.startup__title}>{name}</div>
            <div className={styles.startup__title}>{industry}</div>
          </div>
        </div>
        <div className={styles.startup__description}>{description}</div>
        <div className={styles.startup__circleContainer}>
          <div className={`${styles.startup__circle} ${styles.startup__circle1}`}></div>
          <div className={`${styles.startup__circle} ${styles.startup__circle2}`}></div>
          <div className={`${styles.startup__circle} ${styles.startup__circle3}`}></div>
          <div className={`${styles.startup__circle} ${styles.startup__circle4}`}></div>
          <div className={`${styles.startup__circle} ${styles.startup__circle5}`}></div>
        </div>
      </div>
    </div>
  );
}

export default StartupCard;

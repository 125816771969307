import { useLocation, useNavigate } from "react-router-dom";
import styles from "./tasks.module.css";
import { CustomButton } from "../../ui-kit";
import { SPRINTRETRO } from "../../utils/constants";

function Tasks(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = () => {
    navigate(-1);
  };

  const title =
    location.pathname === SPRINTRETRO ? "Sprint retro" : "Urgent tasks";

  return (
    <div className={`${styles.main} container`}>
      <h1>{title}</h1>
      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default Tasks;

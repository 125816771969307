export const MAIN: string = "/";
export const FOUNDER_MAIN_PATH: string = "/founder-main";
export const COBUILDERMAIN: string = "/co-builder-main";
export const REGISTER: string = "/register";
export const FILTERS: string = "/filters";
export const NUMBER: string = "/:number";

export const UI_KIT_PATH: string = "/uikit";

// FounderRoutes
export const SPRINTS_PATH: string = "/sprints";
export const PROFILE_PATH: string = "/profile";
export const STARTUPTRACK: string = "/startup-track";
export const SPRINTRETRO: string = "/sprint-retro";
export const URGENTTASKS: string = "/urgent-tasks";
export const NEWSPRINT: string = "/new-sprint";

// CoBuilderRoutes
export const MYASSIGNMENTS: string = "/my-assignments";
export const WALLET: string = "/wallet";
export const WALLETTICKET: string = "/wallet-ticket";
export const NEWJOB: string = "/new-job";
export const ACTIONLIST: string = "/action-list";

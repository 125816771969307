import { useNavigate } from "react-router-dom";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import styles from "./assignment.module.css";
import { SquareButton } from "../../ui-kit";
import { MYASSIGNMENTS } from "../../utils/constants";
import CustomSmallButton from "../../ui-kit/CustomSmallButton/CustomSmallButton";

// type AssignmentItem = {
//   nameOne: string;
//   nameTwo: string;
//   nameThree: string;
//   id: number;
//   key?: string;
// };

// type AssignmentProps = {
//   item: AssignmentItem;
// };
//@ts-ignore
function Assignment({ item }: AssignmentProps): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`${MYASSIGNMENTS}/${item.id}`);
  };

  const currentDate: Date = new Date();
  const targetDate: Date = new Date("2024-11-24");
  const timeDifference: number = targetDate.getTime() - currentDate.getTime();
  const daysLeft: number = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  let color: string;

  if (daysLeft < 3) {
    color = "var(--red-sm)";
  } else if (daysLeft < 6) {
    color = "var(--orange-sm)";
  } else {
    color = "var(--green-sm)";
  }

  return (
    <div className={styles.main}>
      <div className={styles.marker} style={{ backgroundColor: color }}></div>
      <div className={styles.common_container}>
        <div className={styles.container}>
          <p className="p2">{item.reference}</p>
          <SquareButton
            icon={<ChevronRightTwoToneIcon />}
            altText="ArrowForwardIcon icon"
            disabled={false}
            variant="dark"
            onClick={handleOnClick}
          />
        </div>
        <div className={styles.container}>
          <h3 className={styles.text_left}>{item.taskShortDto.name}</h3>
          <p className="p2" style={{ color: color }}>
            {`${daysLeft} days`}
          </p>
        </div>
        <div className={styles.container}>
          <p className={`p3 ${styles.text_left}`}>{item.instruction}</p>
          <CustomSmallButton
            text={
              item.status.toLowerCase()[0].toUpperCase() +
              item.status.slice(1).toLowerCase()
            }
            color="var(--violet-7)"
            height="24px"
          />
        </div>
      </div>
    </div>
  );
}

export default Assignment;

import { Route, Routes } from "react-router-dom";

import styles from "./app.module.css";

import {
  MAIN,
  FOUNDER_MAIN_PATH,
  SPRINTS_PATH,
  NEWSPRINT,
  SPRINTRETRO,
  STARTUPTRACK,
  UI_KIT_PATH,
  URGENTTASKS,
  REGISTER,
  MYASSIGNMENTS,
  NUMBER,
  WALLET,
  WALLETTICKET,
  NEWJOB,
  ACTIONLIST,
  FILTERS,
} from "../../utils/constants";

import UiKit from "../../pages/UiKit/UiKit";
import StartupTrack from "../../pages/startupTrack/startupTrack";
import Tasks from "../../pages/tasks/tasks";
import NewSprint from "../../pages/newSprint/newSprint";
import FounderMain from "../../pages/founder/Main/Main";
import MainTemp from "../../pages/main/main-temp";
import Register from "../../pages/register/register";
import MyAssignments from "../../pages/cobuilder/myAssignments/myAssignments";
import WorkOrder from "../../pages/work0order/workOrder";
import Wallet from "../../pages/cobuilder/wallet/wallet";
import WalletTicket from "../../pages/cobuilder/walletTicket/walletTicket";
import NewJob from "../../pages/cobuilder/newJob/newJob";
import Sprints from "../../pages/founder/Sprints/Sprints";
import ActionList from "../../pages/actionList/actionList";
import Filters from "../../pages/filters/filters";

function App(): React.JSX.Element {
  // @ts-ignore
  let tg = window.Telegram.WebApp;
  const userTelegramData = tg?.initDataUnsafe.user;
  const userTelegramColorScheme = tg.colorScheme; // options: light, dark

  return (
    <div className={styles.app}>
      <Routes>
        <Route
          path={MAIN}
          element={
            <MainTemp
              userTelegramData={userTelegramData}
              userTelegramColorScheme={userTelegramColorScheme}
            />
          }
        />
        <Route path={FILTERS} element={<Filters />} />
        {/* FounderRoutes */}
        <Route
          path={FOUNDER_MAIN_PATH}
          element={
            <FounderMain userTelegramData={userTelegramData?.username} />
          }
        />
        <Route path={SPRINTS_PATH} element={<Sprints />} />
        <Route path={STARTUPTRACK} element={<StartupTrack />} />
        <Route path={SPRINTRETRO} element={<Tasks />} />
        <Route path={URGENTTASKS} element={<Tasks />} />
        <Route path={NEWSPRINT} element={<NewSprint />} />

        {/* CoBuilderRoutes */}
        <Route path={MYASSIGNMENTS} element={<MyAssignments />} />
        <Route path={`${MYASSIGNMENTS}${NUMBER}`} element={<WorkOrder />} />
        <Route path={WALLET} element={<Wallet />} />
        <Route path={`${WALLETTICKET}${NUMBER}`} element={<WalletTicket />} />
        <Route path={NEWJOB} element={<NewJob />} />
        <Route
          path={`${MYASSIGNMENTS}${NUMBER}${ACTIONLIST}`}
          element={<ActionList />}
        />

        {/* NoRegisteredRoutes */}
        <Route path={REGISTER} element={<Register />} />

        <Route path={UI_KIT_PATH} element={<UiKit />} />
      </Routes>
    </div>
  );
}

export default App;

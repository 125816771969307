import { useNavigate } from "react-router-dom";
import styles from "./actionList.module.css";
import { CustomButton } from "../../ui-kit";
import TitleContainer from "../../components/titleContainer/titleContainer";

function ActionList(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <div className={`${styles.main} container`}>
      <TitleContainer title={"Actions list"} isLogo={true} isFilter={true} />
      <div className={styles.content}>
        <div className={styles.points_container}>
          <p className={`p2 ${styles.text_left}`}>1.</p>
          <p className={`p2 ${styles.text_left}`}>
            Conduct market research on the global flower industry: key markets,
            competitors, and trends.
          </p>
          <p className={`p2 ${styles.text_left}`}>2.</p>
          <p className={`p2 ${styles.text_left}`}>
            Interview potential flower sellers to understand their pain points
            and needs for such a platform.
          </p>
          <p className={`p2 ${styles.text_left}`}>3.</p>
          <p className={`p2 ${styles.text_left}`}>
            Analyze competitors and identify gaps in the market.
          </p>
        </div>
        <h2>Output</h2>
        <p className={`p2 ${styles.text_left}`}>
          Insights from research and interviews that help refine the platform’s
          unique value proposition (UVP).
        </p>
        <h2 className={styles.text_left}>Deliverables:</h2>
        <div className={styles.section_container}>
          <div className={styles.text_container}>
            <p className={`p2 ${styles.text_left}`}>1.</p>
            <p
              className={`p2 ${styles.text_left}`}
              style={{ textDecoration: "underline" }}
            >
              Artefact URL 1
            </p>
          </div>
          <div className={styles.text_container}>
            <p className={`p2 ${styles.text_left}`}>2.</p>
            <p
              className={`p2 ${styles.text_left}`}
              style={{ textDecoration: "underline" }}
            >
              Artefact URL 2
            </p>
          </div>
          <div className={styles.text_container}>
            <p className={`p2 ${styles.text_left}`}>3.</p>
            <p
              className={`p2 ${styles.text_left}`}
              style={{ textDecoration: "underline" }}
            >
              Artefact URL 3
            </p>
          </div>
        </div>
        <h2 className={styles.text_left}>Success KPI:</h2>
        <div className={styles.section_container}>
          <p className={`p2 ${styles.text_left}`}>- 1</p>
          <p className={`p2 ${styles.text_left}`}>- 2</p>
          <p className={`p2 ${styles.text_left}`}>- 3</p>
        </div>
        <h2 className={styles.text_left}>Instructions:</h2>
        <p className={`p2 ${styles.text_left}`}>Instructions</p>
        <h2 className={styles.text_left}>References:</h2>
        <p className={`p2 ${styles.text_left}`}>References</p>
        <div className={styles.button_container}>
          <CustomButton
            text="Back"
            onClick={handleOnClick}
            color={"var(--violet-7)"}
            textColor={"dark"}
          />
        </div>
      </div>
    </div>
  );
}

export default ActionList;

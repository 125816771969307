import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./newJob.module.css";
import { CustomButton } from "../../../ui-kit";
import TitleContainer from "../../../components/titleContainer/titleContainer";
import Job from "../../../components/job/job";
import { MYASSIGNMENTS } from "../../../utils/constants";

function NewJob(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  const jobs = [
    {
      id: 1,
      nameOne: "Startup name-1",
      nameTwo: "Research and Market Validation Validationa Validat",
      nameThree:
        "Validate the need for a global e-commerce platform for flower sellers for flower sellers for flower",
      position: "Product Manager",
      grade: "Senior-Pomidor",
      bid: 100,
      bonus: 100,
    },
    {
      id: 2,
      nameOne: "Startup name-2",
      nameTwo: "Research and Market Validation Validationa Validat",
      nameThree:
        "Validate the need for a global e-commerce platform for flower sellers for flower sellers for flower",
      position: "Product Manager",
      grade: "Senior-Pomidor",
      bid: 100,
      bonus: 100,
    },
  ];

  return (
    <div className={`${styles.main} container`}>
      <TitleContainer title={"Find new Job"} isLogo={true} isFilter={true} />
      <div className={styles.container}>
        {jobs.map((item) => (
          <Link to={`${MYASSIGNMENTS}/${item.id}`} className={styles.link}>
            <Job item={item} key={uuidv4()} />
          </Link>
        ))}
      </div>
      <CustomButton text="Back" onClick={handleOnClick} />
    </div>
  );
}

export default NewJob;

import React from "react";

type LogoBlockProps = {
  imageSrc?: string;
};

const LogoBlock: React.FC<LogoBlockProps> = ({ imageSrc }) => {
  return (
    <div
      style={{
        width: "60px",
        height: "54px",
        boxSizing: "border-box",
        borderRadius: "12px",
        border: "4px solid var(--violet-gr-2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="logo"
            style={{
              maxWidth: "110%",
              maxHeight: "110%",
              objectFit: "contain",
            }}
          />
        ) : (
          <span
            style={{
              fontSize: "14px",
              color: "var(--violet-gr-3)",
            }}
          >
            logo
          </span>
        )}
      </div>
    </div>
  );
};

export default LogoBlock;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./myAssignments.module.css";
import Assignment from "../../../components/assignment/assignment";
import TitleContainer from "../../../components/titleContainer/titleContainer";
import CustomSmallButton from "../../../ui-kit/CustomSmallButton/CustomSmallButton";
import { CustomButton } from "../../../ui-kit";
import { NEWJOB, WALLET } from "../../../utils/constants";

import { coBuilderMockStartups } from "../../../utils/03_coBuilderMockStartups";

function MyAssignments(): React.JSX.Element {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);

  const handleOnFullListClick = () => {
    setFilter(true);
  };

  const handleOnNewJobClick = () => {
    navigate(NEWJOB);
  };

  const handleOnWalletClick = () => {
    navigate(WALLET);
  };

  const handleOnBackClick = () => {
    setFilter(false);
  };

  // const assignments = [
  //   {
  //     id: 1,
  //     nameOne: "Research and Market Validation",
  //     nameTwo: "Startup Name 1",
  //     nameThree: "Validate the need for a global e-commerce platform...",
  //   },
  //   {
  //     id: 2,
  //     nameOne: "Research and Market Validation",
  //     nameTwo: "Startup Name 2",
  //     nameThree: "Validate the need for a global e-commerce platform...",
  //   },
  //   {
  //     id: 3,
  //     nameOne: "Research and Market Validation",
  //     nameTwo: "Startup Name 3",
  //     nameThree: "Validate the need for a global e-commerce platform...",
  //   },
  //   {
  //     id: 4,
  //     nameOne: "Research and Market Validation",
  //     nameTwo: "Startup Name 4",
  //     nameThree: "Validate the need for a global e-commerce platform...",
  //   },
  //   {
  //     id: 5,
  //     nameOne: "Research and Market Validation",
  //     nameTwo: "Startup Name 5",
  //     nameThree: "Validate the need for a global e-commerce platform...",
  //   },
  //   {
  //     id: 6,
  //     nameOne: "Research and Market Validation",
  //     nameTwo: "Startup Name 6",
  //     nameThree: "Validate the need for a global e-commerce platform...",
  //   },
  // ];

  const assignmentsToShow = filter
    ? coBuilderMockStartups
    : coBuilderMockStartups.slice(0, 3);

  return (
    <div className={`${styles.main} container`}>
      <div className={styles.title_buttons_container}>
        <TitleContainer title="My Assignments" isLogo={true} isFilter={true} />
        <div className={styles.buttons_container}>
          <CustomSmallButton
            text="Assigned"
            color="var(--violet-7)"
            height="24px"
          />
          <CustomSmallButton
            text="In Review"
            color="var(--yellow)"
            height="24px"
          />
          <CustomSmallButton
            text="Revision"
            color="var(--coral)"
            height="24px"
          />
        </div>
      </div>
      <div className={styles.container}>
        {assignmentsToShow?.map((item) => (
          //@ts-ignore
          <Assignment item={item} key={uuidv4()} />
        ))}
      </div>
      {!filter ? (
        <CustomButton
          text="Full List of Tasks"
          onClick={handleOnFullListClick}
          color={"var(--green)"}
          textColor={"dark"}
        />
      ) : (
        ""
      )}
      {!filter ? (
        <CustomButton
          text="Find new Job"
          onClick={handleOnNewJobClick}
          color={"var(--green)"}
          textColor={"dark"}
        />
      ) : (
        ""
      )}
      {!filter ? (
        <CustomButton
          text="My Wallet"
          onClick={handleOnWalletClick}
          color={"var(--green)"}
          textColor={"dark"}
        />
      ) : (
        ""
      )}
      {filter ? (
        <CustomButton
          text="Back"
          onClick={handleOnBackClick}
          color={"var(--violet-7)"}
          textColor={"dark"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default MyAssignments;

import { useLocation, useNavigate } from "react-router-dom";
import styles from "./workOrder.module.css";
import { CustomButton } from "../../ui-kit";
import TitleContainer from "../../components/titleContainer/titleContainer";
import { ACTIONLIST } from "../../utils/constants";

function WorkOrder(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = () => {
    navigate(-1);
  };

  const handleOnActionsListClick = () => {
    navigate(`${location.pathname}${ACTIONLIST}`);
  };

  return (
    <div className={`${styles.main} container`}>
      <TitleContainer title={"Work Order"} isLogo={true} isFilter={true} />

      <div className={styles.data_container}>
        <h3 className={styles.text_right}>#334869</h3>
        <p className={`p2 ${styles.text_left}`}>
          Research and Market Validation
        </p>
        <h3 className={styles.text_right}>Goal:</h3>
        <p className={`p2 ${styles.text_left}`}>
          Validate the need for a global e-commerce platform for flower sellers
        </p>

        <h3 className={styles.text_right}>Due Date:</h3>
        <p className={`p2 ${styles.text_left}`}>October 7th, 2024</p>

        <h3 className={styles.text_right}>Position:</h3>
        <p className={`p2 ${styles.text_left}`}>Product Manager</p>

        <h3 className={styles.text_right}>Grade:</h3>
        <p className={`p2 ${styles.text_left}`}>Middle</p>
      </div>

      <CustomButton
        text="Review Feedback"
        onClick={handleOnClick}
        color={"var(--light-yellow)"}
        textColor={"dark"}
      />
      <CustomButton text="Actions List" onClick={handleOnActionsListClick} />
      <div className={styles.logo_texts_container}>
        <div className={styles.logo}></div>
        <div className={styles.texts_container}>
          <div className={styles.text_container}>
            <h2>Bid:</h2>
            <p className="p1">100</p>
          </div>
          <div className={styles.text_container}>
            <h2>Bonus:</h2>
            <p className="p1">100</p>
          </div>
        </div>
      </div>
      <div className="narrow_buttons_container">
        <CustomButton
          text="Done"
          onClick={handleOnClick}
          color={"var(--yellow)"}
          textColor={"dark"}
          size="narrow"
        />
        <CustomButton
          text="Drop"
          onClick={handleOnClick}
          color={"var(--coral)"}
          textColor={"dark"}
          size="narrow"
        />
      </div>
      <CustomButton
        text="Back"
        onClick={handleOnClick}
        color={"var(--violet-7)"}
        textColor={"dark"}
      />
    </div>
  );
}

export default WorkOrder;

import React from "react";
import { Button } from "@mui/material";

type CustomButtonProps = {
  icon?: React.ReactNode;
  altText?: string;
  disabled?: boolean;
  variant?: "light" | "dark";
  onClick?: () => void;
  text?: string;
  img?: string;
};

const RoundButton: React.FC<CustomButtonProps> = ({
  icon,
  altText,
  disabled = false,
  variant = "light",
  onClick,
  text,
  img,
}) => {
  const backgroundColor =
    variant === "light" ? "var(--violet-3)" : "var(--violet-gr-2)";
  const hoverBackgroundColor =
    variant === "light" ? "var(--violet-opacity)" : "var(--violet-gr-3)";
  const iconColor =
    variant === "light" ? "var(--violet-gr-2)" : "var(--text-light)";

  return (
    <Button
      sx={{
        width: "40px",
        height: "40px",
        backgroundColor: backgroundColor,
        borderRadius: "50%",
        minWidth: "40px",
        minHeight: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--violet-gr-2)",
        boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.15)",
        position: "relative",
        "&:hover": {
          backgroundColor: hoverBackgroundColor,
        },
      }}
      disabled={disabled}
      aria-label={altText}
      onClick={onClick}
    >

      {img ? (
        <img
          src={img}
          alt={altText || "avatar"}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      ) : icon ? (
        React.cloneElement(icon as React.ReactElement, {
          style: { color: iconColor },
        })
      ) : null}
      {text}
    </Button>
  );
};

export default RoundButton;

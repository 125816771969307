import { useNavigate } from "react-router-dom";
import styles from "./walletTicket.module.css";
import { CustomButton, CustomSquare } from "../../../ui-kit";
import TitleContainer from "../../../components/titleContainer/titleContainer";

function WalletTicket(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <div className={`${styles.main} container`}>
      <TitleContainer title={"Wallet"} isLogo={true} isFilter={false} />
      <div className={styles.main_info_container}>
        <div className={styles.info_container}>
          <p className="p2">Balance STT</p>
          <h3>Startup Name 1</h3>
        </div>
        <CustomSquare />
        <h2 className={styles.text_right}>542</h2>
      </div>
      <div className={styles.data_button_container}>
        <div className={styles.data_container}>
          <h2 className={styles.text_right}>+ 400</h2>
          <p className={`p2 ${styles.text_left}`}>Order work name</p>
          <p className={`p2 ${styles.text_right}`}>ticker</p>
          <p className={`p2 ${styles.text_left}`}>Date - October 7th, 2024</p>
          <h2 className={styles.text_right}>+ 142</h2>
          <p className={`p2 ${styles.text_left}`}>
            Research and Market Validation
          </p>
          <p className={`p2 ${styles.text_right}`}>ticker</p>
          <p className={`p2 ${styles.text_left}`}>October 7th, 2024</p>
        </div>
        <CustomButton
          text="Withdrow"
          onClick={handleOnClick}
          color={"var(--green)"}
          textColor={"dark"}
        />
      </div>
      <CustomButton
        text="Back"
        onClick={handleOnClick}
        color={"var(--violet-7)"}
        textColor={"dark"}
      />
    </div>
  );
}

export default WalletTicket;

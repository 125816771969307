import { Link, useNavigate } from "react-router-dom";
import styles from "./wallet.module.css";
import { CustomButton } from "../../../ui-kit";
import TitleContainer from "../../../components/titleContainer/titleContainer";
import { WALLETTICKET } from "../../../utils/constants";

function Wallet(): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <div className={`${styles.main} container`}>
      <TitleContainer title={"Wallet"} isLogo={true} isFilter={false} />

      <div className={styles.data_container}>
        <Link to={`${WALLETTICKET}/${1}`} className={styles.wallet_container}>
          <h2 className={styles.text_right}>542</h2>
          <h2 className={styles.text_left}>Startup Name 1</h2>
          <p className={`p2 ${styles.text_right}`}>ticker</p>
          <p className={`p2 ${styles.text_left}`}>
            Research and Market Validation
          </p>
          <p className={`p2 ${styles.text_right}`}></p>
          <p className={`p2 ${styles.text_left}`}>October 7th, 2024</p>
        </Link>
        <Link to={`${WALLETTICKET}/${2}`} className={styles.wallet_container}>
          <h2 className={styles.text_right}>142</h2>
          <h2 className={styles.text_left}>Startup Name 2</h2>
          <p className={`p2 ${styles.text_right}`}>ticker</p>
          <p className={`p2 ${styles.text_left}`}>
            Research and Market Validation
          </p>
          <p className={`p2 ${styles.text_right}`}></p>
          <p className={`p2 ${styles.text_left}`}>October 7th, 2024</p>
        </Link>
      </div>

      <CustomButton
        text="Back"
        onClick={handleOnClick}
        color={"var(--violet-7)"}
        textColor={"dark"}
      />
    </div>
  );
}

export default Wallet;

import { useNavigate } from "react-router-dom";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import { RoundButton } from "../../ui-kit";
import { COBUILDERMAIN, FILTERS } from "../../utils/constants";
import { CoBuilderDto } from "../../utils/types";
import { mockData } from "../../utils/01.mockData";

type TitleContainerProps = {
  title: string;
  isLogo: boolean;
  isFilter: boolean;
};

function TitleContainer({
  title,
  isLogo,
  isFilter,
}: TitleContainerProps): React.JSX.Element {
  const navigate = useNavigate();

  const coBuilderMockData: CoBuilderDto | undefined =
    mockData?.coBuilderMockData?.coBuilderDto;

  const coBuilderInitials = `${coBuilderMockData?.userDto.firstName.charAt(
    0
  )}${coBuilderMockData?.userDto.lastName.charAt(0)}`;

  const handleFilterClick = () => {
    navigate(FILTERS);
  };

  const handleAvatarClick = () => {
    navigate(COBUILDERMAIN);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "40px",
          width: "40px",
        }}
      >
        {isLogo ? (
          <RoundButton
            disabled={false}
            onClick={handleAvatarClick}
            text={coBuilderInitials}
          />
        ) : (
          ""
        )}
      </div>
      <h1>{title}</h1>
      <div
        style={{
          height: "40px",
          width: "40px",
        }}
      >
        {isFilter ? (
          <RoundButton
            icon={<TuneTwoToneIcon />}
            altText="TuneTwoToneIcon icon"
            disabled={false}
            variant="dark"
            onClick={handleFilterClick}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default TitleContainer;

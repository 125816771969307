import { useNavigate } from "react-router-dom";
import {
  FOUNDER_MAIN_PATH,
  MYASSIGNMENTS,
  REGISTER,
  UI_KIT_PATH,
} from "../../utils/constants";
import { CustomButton } from "../../ui-kit";
import styles from "./main-temp.module.css";

// @ts-ignore
function MainTemp({
  // @ts-ignore
  userTelegramData,
  // @ts-ignore
  userTelegramColorScheme,
}): React.JSX.Element {
  const navigate = useNavigate();

  const handleOnClickToFounder = () => {
    navigate(FOUNDER_MAIN_PATH);
  };
  const handleOnClickToCoBuilder = () => {
    navigate(MYASSIGNMENTS);
  };
  const handleOnClickToRegister = () => {
    navigate(REGISTER);
  };
  const handleOnClickToUI = () => {
    navigate(UI_KIT_PATH);
  };

  const colorScheme =
    userTelegramColorScheme === "dark" && userTelegramData
      ? { color: "var(--white)" }
      : { color: "var(--black)" };

  return (
    <div
      className={`${styles.main} ${
        userTelegramColorScheme === "light" ? styles.white : styles.black
      } container`}
    >
      <h1 style={colorScheme}>
        Temporary page to check different branches and test telegram data and
        telegram color scheme
      </h1>
      <h2 style={colorScheme}>
        {`${
          userTelegramData
            ? userTelegramData.first_name
            : "@HereShouldBeTelegramFirstName"
        }`}
      </h2>
      <h2 style={colorScheme}>
        {`${
          userTelegramData
            ? userTelegramData.last_name
            : "@HereShouldBeTelegramLastName"
        }`}
      </h2>
      <h2 style={colorScheme}>
        {`${
          userTelegramData
            ? userTelegramData.username
            : "@HereShouldBeTelegramUserName"
        }`}
      </h2>
      <h2 style={colorScheme}>
        {`${
          userTelegramData ? userTelegramData.id : "@HereShouldBeTelegramId"
        }`}
      </h2>
      <h2 style={colorScheme}>
        {`${
          userTelegramColorScheme
            ? userTelegramColorScheme
            : "@HereShouldBeTelegramUserColorScheme"
        }`}
      </h2>

      <h1 style={colorScheme}>Temporary buttons to check branch</h1>
      <CustomButton
        text="To Founder Main Page"
        onClick={handleOnClickToFounder}
      />
      <CustomButton
        text="To CoBuilder Main Page"
        onClick={handleOnClickToCoBuilder}
      />
      <CustomButton text="To Register Page" onClick={handleOnClickToRegister} />
      <CustomButton text="To UI-Kit Page" onClick={handleOnClickToUI} />
    </div>
  );
}

export default MainTemp;
